$color-fade-to-black: #313131;
$color-lana-del-grey: #979797;
$color-grey-worm: #c4c4c4;
$color-dorian-grey: #dcdcdc;
$color-father-john-misty: #f3f3f3;
$color-the-grey-album: #b3b3b3;
$color-the-white-album: #fff;

$color-blue-loading-screen: #516072;
$color-blue-monday: #5b6b7d;
$color-blueish: #809db2;
$color-karl: #edf4f9;
$color-bloop: #f0a6ff;

$color-bahamas-blue: #00669d;
$color-tangled-up-in-blue: #0084cc;
$color-bluejay-way: #169ae1;
$color-mr-blue-sky: #40b6f5;
$color-minty-fresh: #94f5c1;
$color-come-sail-away: #10d1b2;
$color-the-green-album: #6bda93;
$color-granny-smith: #45c071;

$color-cherry-pie: #f0006a;
$color-crunchberry: #ff0e88;
$color-lady-in-red: #ff234e;
$color-peaches: #f5a623;
$color-goldfinger: #ffe453;
$color-lemonade: #f5ff93;

$color-deep-purple: #482250;
$color-bruise-purple: #693673;
$color-purple-rain: #682e9a;
$color-dj-purple: #8c399d;
$color-purple-haze: #af5ebf;

$color-white: $color-the-white-album;
$color-black: $color-fade-to-black;
$color-link: $color-tangled-up-in-blue;
