.nn {
  padding: 0;
}

.sm {
  padding: 8px 0;
}

.md {
  padding: 12px 0;
}

.lg {
  padding: 18px 0;
}
