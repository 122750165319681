$margin-xxsmall: 4px;
$margin-xsmall: 8px;
$margin-small: 16px;
$margin-medium: 24px;
$margin-large: 32px;
$margin-xlarge: 40px;
$margin-xxlarge: 48px;
$margin-xxxlarge: 56px;
$margin-xxxxlarge: 64px;
$margin-above-header-logo: 50px;

$max-content-width: 480px;
$standard-border-radius: 5px;
$large-border-radius: 10px;
$xlarge-border-radius: 20px;
$xxlarge-border-radius: 40px;
$fixed-bottom-clear: 100px;
