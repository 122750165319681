$stroke-width: 2px;
$x-dimensions: 18px;
//
//@define-mixin arrow $orientation-name, $color, $arrow-dimensions, $orientation-rotation {
//  .$(orientation-name)Arrow {
//    display: flex;
//
//    &::before {
//      border-style: solid;
//      border-width: $stroke-width $stroke-width 0 0;
//      color: $color;
//      content: '';
//      display: inline-block;
//      height: $arrow-dimensions;
//      left: 0.25em;
//      position: relative;
//      top: 0.15em;
//      transform: rotate($orientation-rotation);
//      vertical-align: top;
//      width: $arrow-dimensions;
//    }
//  }
//}

.closeX {
  height: $x-dimensions;
  position: relative;
  width: $x-dimensions;

  &::before,
  &::after {
    background-color: $color-blue-monday;
    content: '';
    height: $stroke-width;
    left: -2px;
    position: absolute;
    top: 8px;
    width: 23px;
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
